<script context="module">
  import is from 'is_js'
  let audio

  if (true) {
    audio = new Audio('/tick.wav')
    audio.volume = 0.02
  }

  let hoverPlay = null

  if (is.not.touchDevice()) {
    hoverPlay = async (e) => {
      await audio.play()
      audio.currentTime = 0
    }
  }
</script>

<script>
  import { stores } from '@sapper/app'
  import { fly } from 'svelte/transition'
  import { sineInOut } from 'svelte/easing'

  export let href
  export let delay

  const { page } = stores()

  let props = {}

  if (/^[#\?]/.test(href)) {
    props['sapper:noscroll'] = ''
  }

  let hrefProp = /^[#\?]/.test(href) ? `${$page.path}${href}` : href
</script>

<a {...props}
  on:click
  on:mouseenter={!!hrefProp ? hoverPlay : null}
  in:fly={{ x: -20, delay: delay || 0, duration: 200, easing: sineInOut }}
  target={href && /^https?:\/\//.test(href) ? '_blank' : null}
  rel={href && /^https?:\/\//.test(href) ? 'noreferrer' : 'preload'}
  href={hrefProp}
>
  <span><slot></slot></span>
</a>

<style>a{font-size:50px;display:block;text-decoration:none;position:relative;padding:8px 0 10px;-webkit-transition:color .15s ease;transition:color .15s ease;line-height:1;letter-spacing:-2px}a::before{content:"";position:absolute;top:0;left:-35px;width:100%;height:100%;background:#2be9b9;opacity:0;padding:0 35px;-webkit-transition:opacity .15s ease,width .25s ease;transition:opacity .15s ease,width .25s ease;-webkit-box-sizing:content-box;box-sizing:content-box}a span{position:relative;z-index:1;display:inline!important}@media (min-width:480px){a:link.choosen,a:link:hover{color:#000}a.choosen::before,a:link:hover::before{width:100%;opacity:1}a.no-pointer-events{pointer-events:none}}@media (max-width:480px){a{font-size:35px}a:link:active{color:#2be9b9}a::before{left:-30px;padding:0 30px}}</style>
